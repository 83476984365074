//
// Imports all assets for the website
//

// logos
import logo from "../assets/img/logos/logo.svg";

// icons
import appStore from "../assets/img/icons/app-store.svg";
import arrowUpRight from "../assets/img/icons/arrow-up-right.png";
import playStore from "../assets/img/icons/google-play.svg";

// page content images
// how it works
import hit1 from "../assets/img/content/how-it-works-1.svg";
import hit2 from "../assets/img/content/how-it-works-2.svg";
import hitMobile from "../assets/img/content/how-it-works-mobile.svg";

// about us page
import frames from '../assets/img/content/about-us-frames.webp';
import frames2x from '../assets/img/content/about-us-frames@2x.webp';
import couple from '../assets/img/content/about-us-couple.webp';
import couple2x from '../assets/img/content/about-us-couple@2x.webp';
import iphone from '../assets/img/content/about-us-iphone.webp';
import iphone2x from '../assets/img/content/about-us-iphone@2x.webp';

//homepage
import home1 from '../assets/img/content/home-1.webp';
import home2 from '../assets/img/content/home-2.webp';
import home3 from '../assets/img/content/home-3.webp';
import home4 from '../assets/img/content/home-4.webp';
import home5 from '../assets/img/content/home-5.webp';
import home6 from '../assets/img/content/home-6.webp';
import home12x from '../assets/img/content/home-1@2x.webp';
import home22x from '../assets/img/content/home-2@2x.webp';
import home32x from '../assets/img/content/home-3@2x.webp';
import home42x from '../assets/img/content/home-4@2x.webp';
import home52x from '../assets/img/content/home-5@2x.webp';
import home62x from '../assets/img/content/home-6@2x.webp';
import homeDesktopBg from '../assets/img/content/home-background.svg';

//slideshow
import foreground from "../assets/img/content/slide-foreground.webp";
import foregroundHighRes from "../assets/img/content/slide-foreground-2x.webp";
import content1 from "../assets/img/content/slide-content-1.webp";
import content2 from "../assets/img/content/slide-content-2.webp";
import content3 from "../assets/img/content/slide-content-3.webp";
import content4 from "../assets/img/content/slide-content-4.webp";
import content5 from "../assets/img/content/slide-content-5.webp";
import content1HighRes from "../assets/img/content/slide-content-1-2x.webp";
import content2HighRes from "../assets/img/content/slide-content-2-2x.webp";
import content3HighRes from "../assets/img/content/slide-content-3-2x.webp";
import content4HighRes from "../assets/img/content/slide-content-4-2x.webp";
import content5HighRes from "../assets/img/content/slide-content-5-2x.webp";

const createImgObjSrcset = (img, highRes, alt = '') => {
  return(
    { ...createImgObj(highRes, alt),
      srcset: img + " 700w, " + highRes + " 1000w" });
}

const createImgObj = (img, alt = '') => {
  return {src: img, alt: alt};
}

const logos = { mainLogo: createImgObj(logo, 'Main logo for Pixpix') };

const icons = {
  appStore:     createImgObj(appStore, "Logo for Apple's app store"),
  arrowUpRight: createImgObj(arrowUpRight, 'Arrow point up and to the rigth'),
  playStore:    createImgObj(playStore, "Logo of Google's play store"),
};

const hit = {
  hit1: createImgObj(hit1, 'Image showing how Pixpix works. Step 1, connect your account. Step 2, select the photos you want to print. Step 3, choose size and finish. Step 4, place order. Step 5, photos arrive'),
  hit2: createImgObj(hit2, ''),
  hitMobile: createImgObj(hitMobile, 'Image showing how Pixpix works. Step 1, connect your account. Step 2, select the photos you want to print. Step 3, choose size and finish. Step 4, place order. Step 5, photos arrive'),
};

const aboutUs = {
  frames: createImgObjSrcset(frames, frames2x, "Collection of photos in frames"),
  couple: createImgObjSrcset(couple, couple2x, "Couple building a heart by sticking photos on a wall"),
  iphone: createImgObjSrcset(iphone, iphone2x, "iPhone with the Pixpix app home screen"),
};

const homepage = {
  home1:  createImgObjSrcset(home1, home12x, "Group of young women walking arm in arm"),
  home2:  createImgObjSrcset(home2, home22x, "Woman smiling and posing for a photo"),
  home3:  createImgObjSrcset(home3, home32x, "Group of young women in front of a sunset"),
  home4:  createImgObjSrcset(home4, home42x, "A couple with the woman riding on the man's back"),
  home5:  createImgObjSrcset(home5, home52x, "Woman singing in front of a crowd"),
  home6:  createImgObjSrcset(home6, home62x, "Puppy laying down in grass in front of a frisbee"),
  homeBg: createImgObj(homeDesktopBg, ''),
};

const slideshow = {
  foreground: createImgObjSrcset(foreground, foregroundHighRes, "Slideshow showing the Pixpix app and how to use it"),
  content1: createImgObjSrcset(content1, content1HighRes),
  content2: createImgObjSrcset(content2, content2HighRes),
  content3: createImgObjSrcset(content3, content3HighRes),
  content4: createImgObjSrcset(content4, content4HighRes),
  content5: createImgObjSrcset(content5, content5HighRes),
}


export { logos, icons, hit, aboutUs, homepage, slideshow };

import Header         from '../components/Header';
import ContactUs      from '../components/ContactUs';

import { useTitle } from 'react-use';
const ContactPage = () => {

  useTitle('Pixpix | Contact');
  const title = "If you have any queries, please email us at: hello@pixpix.app";
  const subtitle = "We’re here to help you print beautiful photos instantly.";

  return (
    <div className="App">
      <Header alternative={false}/>
      <ContactUs title={title} subtitle={subtitle}/>
    </div>
  )
}

export default ContactPage;

import './styles/index.scss';


import ScrollToTop    from './components/ScrollToTop';
import HomePage       from './pages/HomePage';
import ContactPage    from './pages/ContactPage';
import PricingPage    from './pages/PricingPage';
import AboutUsPage    from './pages/AboutUsPage';
import HowItWorksPage from './pages/HowItWorksPage';
import TermsOfUsePage from './pages/TermsOfUsePage';
import FAQPage        from  './pages/FAQPage';
// import BetaSignup     from  './pages/BetaSignup';
import PrivacyPolicyPage  from './pages/PrivacyPolicyPage';

import { BrowserRouter, Switch, Route } from "react-router-dom";
import Footer from './components/Footer';
import MailchimpNewsletter from './components/MailchimpNewsletter';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App-wrapper">
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path={["/contact-us", "/contact"]}>
            <ContactPage />
          </Route>
          <Route path="/terms-of-use">
            <TermsOfUsePage />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicyPage />
          </Route>
          <Route path="/pricing">
            <PricingPage />
          </Route>
          <Route path={["/about-us", "/about"]}>
            <AboutUsPage />
          </Route>
          <Route path={["/how-it-works", "/how-it", "/how"]}>
            <HowItWorksPage />
          </Route>
          <Route path={"/faq"}>
            <FAQPage />
          </Route>
          <Route>
            <HomePage />
          </Route>
        </Switch>

        <MailchimpNewsletter />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

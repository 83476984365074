
import { useState } from "react";

const MailchimpNewsletter = () => {

  const [email, setEmail] = useState("");
  const [b57727, setB57727] = useState("");

  return (
    <>
      <div href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" className="mailchimp-newsletter">
        <div id="mc_embed_signup" className="mailchimp-newsletter-form">
          <div className="info">
            <h2 className="heading">Sign up to our newsletter for discounts, special offers and the latest news from PixPix!</h2>
          </div>

          <form action="https://app.us14.list-manage.com/subscribe/post?u=577276dcf3654d4827cd36618&amp;id=b0d0447bb4&amp;f_id=00e6efe0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <div className="mc-field-group">

                <input type="email" name="EMAIL" className="required email input" id="mce-EMAIL" placeholder="Email*"
                  value={email} onChange={(e) => setEmail(e.target.value)}/>
              </div>
              <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{ display: "none"}}></div>
                <div className="response" id="mce-success-response" style={{ display: "none"}}></div>
              </div>
              <div style={{ position: "absolute", left: '-5000px'}} aria-hidden="true">
                <input type="text" name="b_577276dcf3654d4827cd36618_b0d0447bb4" tabindex="-1"
                    value={b57727} onChange={(e) => {setB57727(e.target.value)}}/>
              </div>
              <div className="optionalParent">
                  <div className="clear foot">
                      <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button pill" />
                  </div>
              </div>
              </div>
          </form>
        </div>
        <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
        <script type='text/javascript'  dangerouslySetInnerHTML={{ __html: `(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[7]='MMERGE7';ftypes[7]='dropdown';fnames[8]='MMERGE8';ftypes[8]='dropdown';fnames[5]='MMERGE5';ftypes[5]='radio';fnames[6]='MMERGE6';ftypes[6]='text';}(jQuery));var $mcj = jQuery.noConflict(true);` }}></script>
      </div>
    </>
  );
};

export default MailchimpNewsletter;
const faq = [
  {
    category : 'Getting started with Pixpix',
    qas : [
      {
        question: 'How do I start using Pixpix?',
        answer : `Download the Pixpix app from the App Store (iOS) #link or Google Play Store (Android) #link. Once the app is downloaded, simply give the app permission to connect to your photo library or your Instagram account, and you're ready to go!`,
        links : ['https://apps.apple.com/us/app/pixpix/id1589844208', 'https://play.google.com/store/apps/details?id=app.pixpix'],
      },
      {
        question: 'How do I manage my account?',
        answer : `You have the option of creating an account after placing your first order. Once you've created an account, you can manage it from the Settings section of the app. From this section, you can view and modify your details, notification preferences, your connected services, saved payment methods, and delivery addresses. You can also sign out or delete your account.`,
      },
      {
        question: 'How do I select photos to print?',
        answer : `You can access photos via your phone's photo library or Instagram account. To select photos, make sure you've given Pixpix permission to connect to your photo library or your Instagram account, then choose the service you want to select photos from via the Pixpix home screen or menu. Your photos from that service will load in Pixpix and from there, simply click on the photo you wish to edit and print.`,
      },
      {
        question: 'How do I connect to Instagram?',
        answer : `To connect to Instagram, you will need to give Pixpix permission to access your Instagram account. Select Instagram, then Manage Access from the Pixpix home screen to log in to Instagram and allow Pixpix to access your photos.`,
      },
      {
        question: 'Can I choose photos from a multi-photo Instagram post?',
        answer : `Yes you can! Pixpix will show the first photo in the multi-photo post – simply click on that, then swipe to choose the photo you want to edit and print. Once the photo you want is on screen, click on the Edit button to move to the next step.`,
      },
      {
        question: 'Can I choose photos from my Instagram story?',
        answer : `Unfortunately no; photos from your Instagram story cannot be imported into Pixpix, so cannot be edited or printed.`,
      },
      {
        question: `Can I choose photos from someone else's Instagram account?`,
        answer : `Pixpix needs your permission to connect to your Instagram account, so you cannot choose photos from anyone's Instagram account except your own.`,
      },
      {
        question: 'What sort of images can I print with PixPix?',
        answer : `You can print most images, as long as you have the intellectual rights to them! That said, during the PixPix processes, people are involved and will see your photos. Please don’t use PixPix to print anything you don’t want others to see or that may cause upset. PixPix reserves the right not to print images that we consider inappropriate. We will contact you with an explanation and refund if your images fall into this category.`,
      },
      {
        question: `Why is the PixPix app going slow?`,
        answer : `Our aim is to make PixPix a fast and simple photo printing solution, but sometimes things happen outside our control! If you find PixPix is working slowly for you, check your network speed – it may be affecting the app performance. If you’re using data (3G, 4G or 5G) this may also be negatively impacting the app. Finally, check the size of your images – large images (more than 3MB) may cause the app to operate more slowly. If none of these apply to you and you’re still experiencing a slow experience, please contact us (#link) and we can look into this for you.`,
        links: ['mailto:hello@pixpix.app']
      },
    ]
  },
  {
    category : 'Editing and readying photos for print',
    qas : [
      {
        question: 'How do I edit my photos?',
        answer : `After selecting the photo you want to print, you'll be taken to the edit screen. Here, you can change the image orientation, crop the image, choose colour or black and white prints, choose from a matte or glossy finish, add a border to your image, and choose your print size. More advanced editing, e.g. colour adjustments, should be done in another app prior to importing the photo into Pixpix.`,
      },
      {
        question: `I'm trying to edit a landscape-oriented photo, but it's showing up in portrait orientation?`,
        answer : `By default, all images are shown in portrait orientation first. It's easy to change – just click on the white crop button on the bottom right corner of your image while you're in edit mode, and it'll revert to landscape orientation.`,
      },
      {
        question: 'What size photos can I print?',
        answer : `Our print sizes are 5 x 7", 6 x 8", 8 x 8", 10 x 10", 8 x 10", and 10 x 12".`,
      },
      {
        question: 'Can I order multiple prints of one photo?',
        answer : `Yes – to increase or decrease the quantity of prints you want, use the arrows either side of the image quantity on the edit screen, or the plus or minus buttons on either side of the image quantity in the cart.`,
      },
      {
        question: 'How do I make sure my prints turn out well?',
        answer :
        `We pride ourselves on our print quality! For the best results, consider your edits carefully and print at an appropriate size:
#list#list`,
        lists: ['Instagram photos are usually a lower resolution but are perfectly fine for photo prints that are smaller in size (we recommend a max print size of 5 x 7" for the best results from Instagram photos).',
        'Photos from a digital camera, or most smart phones, are usually of a higher resolution and good for most sizes.'],
      },
    ]
  },
  {
    category : 'Ordering Pixpix prints',
    qas : [
      {
        question: 'How do I place an order?',
        answer : `After selecting and editing a photo, choose your image quantity and click on the Add to Bag button at the bottom of the screen to add the print(s) to your order. When you're ready to order, select the bag on the top right of your screen to be taken to your cart. Here you can review your order, change the quantity of images, and remove items from your cart. Click on the Next button on the bottom of the screen to add your delivery details, then when you're ready, click on the Place Order button to add your payment details and finish placing your order.`,
      },
      {
        question: 'What are my payment options?',
        answer : `We accept VISA, Mastercard and American Express as well as debit cards.`,
      },
      {
        question: 'What if I need to cancel or change an order?',
        answer : `Unfortunately, once your order has been received we cannot change or cancel it. Please make sure you're completely happy with your order before you place it.`,
      },
      {
        question: 'How long will it take for my prints to arrive?',
        answer : `Not long! Delivery times vary depending on what part of the country you're in, but generally speaking you can expect your order to arrive within 72 business hours. Orders placed on Fridays or over the weekend may take a little longer. When your order is dispatched, you'll receive a tracking number so you can keep an eye on your anticipated delivery date.`,
      },
      {
        question: 'What is the delivery charge?',
        answer : `We have a flat delivery charge of $5.95 for most of New Zealand. Rural Delivery addresses have a flat delivery charge of $7.95.`,
      },
      {
        question: `What if I'm not happy with my prints?`,
        answer : `We pride ourselves on the quality of our prints. For great prints, we suggest following our tips under #link If you did this and you're still not happy, please get in touch and we will be happy to help you!`,
        links: ['#How do I make sure my prints turn out well?']
      },
    ]
  },
  {
    category : 'Security',
    qas : [
      {
        question: 'What information do you store on the app or in the cloud?',
        answer :
        `We store the minimum amount of your data possible to ensure a great service. The data we store includes your name, email address, your order details, feedback, and photos you choose to print (we do not scrape or store your other photos).

We also store connected service keys, e.g. Instagram. Please note this does not include your actual login, username, or password for any given service. All login details are handled by that service directly. PixPix will never ask you for your login credentials and you should never give them out.

We also capture basic analytics about your usage to help us find issues, trigger notifications and improve our service.`,
      },
      {
        question: 'How can I be sure my data is secure?',
        answer :
        `Access to PixPix is only possible via our mobile phone applications that enforce an encrypted (TLS) connection between all services. This ensures that the connection is private and that data won’t be stolen between your device and our servers.

Once your data gets to our servers, it is stored on Google Cloud Platform. We adhere and heavily rely on Google Cloud Platform's security best practices. This essentially means you have a dedicated, full-time team looking after the security of your data. You can read more about this #link.

Data pulled from third party services such as Instagram is stored temporarily on your device, but is only shared to our servers so that we can manipulate it and send it to our printing service.

All of your data is encrypted end to end and locked into secure storage, database or memory. You can read more about this #link.

You can request to have your data purged at any time by contacting us at #link.`,
        links : ['https://cloud.google.com/security/best-practices', 'https://cloud.google.com/security/encryption/default-encryption', 'mailto:hello@pixpix.app']
      },
    ]
  }
]

export default faq;

import { useTitle } from 'react-use';
import Header     from '../components/Header';
import PageBody   from '../components/PageBody';

const TextPage = ({ title, content, pageTitle = 'PixPix', dataPage = '', contactBlurb = false }) => {

  useTitle(pageTitle);

  return (
    <div className="App" data-page={dataPage}>
      <Header alternative={false}/>
      <PageBody title={title} content={content} contactBlurb={contactBlurb}/>
    </div>
  )
}

export default TextPage;
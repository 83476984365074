
import { logos }         from '../assets/images';
import { NavLink }  from 'react-router-dom';

const Footer = () => {

  return(
    <nav className="footer">
      <div className="footer--top">
        <img  src={logos.mainLogo.src} alt={logos.mainLogo.alt} className='footer--logo'
              height="150" width="100"/>
        <p className="footer--tag-line">Photos made easy</p>
      </div>
      <div className="footer--bottom">
        <NavLink className="footer--bottom--mobile-link" to="/how-it-works" exact>How it Works</NavLink>
        <NavLink className="footer--bottom--mobile-link" to="/about-us" exact>About Us</NavLink>
        <NavLink className="footer--bottom--mobile-link" to="/pricing" exact>Pricing</NavLink>
        <NavLink className="left-align" to="/contact" exact>Contact Us</NavLink>
        <NavLink to="/terms-of-use" exact>Terms of Use</NavLink>
        <NavLink to="/privacy-policy" exact>Privacy Policy</NavLink>
        <p className="footer--copyright right-align">Copyright {(new Date().getFullYear())}, Pixpix</p>
      </div>
    </nav>
  );
}

export default Footer;
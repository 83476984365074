
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import { useTitle } from "react-use";

//
// Pricing page
//

const PriceTable = (sizes, options, delivery) => {
  return (
    <>
      <table>
        <thead>
          <tr>
            <th align="left">Pricing</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {sizes.map( (size, ix) => {
            return (
              <tr key={ix}>
                <td align="left">{size.content}</td>
                <td align="right">{size.price}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th align="left">Options</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {options.map( (option, ix) => {
            return (
              <tr key={ix}>
                <td align="left">{option.content}</td>
                <td align="right">N/C</td>
              </tr>
            )
          })}
        </tbody>
      </table>
      {/* <table>
        <thead>
          <tr>
            <th align="left">Delivery</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {delivery.map( (delivery, ix) => {
            return (
              <tr key={ix}>
                <td align="left">{delivery.content}</td>
                <td align="right">{delivery.price}</td>
              </tr>
            )
          })}
        </tbody>
      </table> */}
    </>
  )
}

const PricingPage = () => {

  useTitle('Pixpix | Pricing');

  const sizes = [
    {content: '5x7”   (12.7x17.78cm)',    price: '50c'},
    {content: '6x8”   (15.24x20.32cm)',   price: '$1.25'},
    {content: '8x10”  (20.32x25.4cm)',   price: '$1.50'},
    {content: '10x10” (25.4x25.4cm)',   price: '$2.00'},
    {content: '10x12” (25.4x30.48cm)',  price: '$2.25'},
    {content: 'Packing and freight',  price: '$5.95'},
    {content: 'Rural Delivery',       price: '$7.95'},
  ];

  const options = [
    {content: 'Black & White / Color'},
    {content: 'Border / No Border'},
    {content: 'Matte / Glossy'},
  ];

  const delivery = [
    {content: 'Packing and freight',  price: '$5.95'},
    {content: 'Rural Delivery',       price: '$7.95'},
  ]

  return (
    <div className="App" data-layout="pricing">
      <Header alternative={false}/>
      <PageTitle content="Pricing" />
      <div className="pricing" data-layout="five2four1">
        <div className="pricing--text">
          <p>Pixpix is committed to delivering high quality prints at great prices.</p>
          <p>All prices are in NZD and include GST</p>
        </div>
        <br/>
        <div className="pricing--table">
          {PriceTable(sizes, options, delivery)}
        </div>
        <br/>
      </div>
    </div>
  )
}

export default PricingPage;

import CTA from "./CTA";

//
// Image and text aside component
//

const Image = ({image}) => {

  const divClassName = "image-and-text--image " + ( image.divStyle ?? '' );
  const imageClassName = image.imageStyle ?? '';

  return (
    <div className={divClassName}>
      <img src={image.meta.src} srcSet={image.meta.srcset} alt={image.meta.alt} className={imageClassName}/>
    </div>
  )
}

const Text = ({text, cta}) => {
  const allowedTitleTypes = ['h1', 'h2'];
  const titleType = allowedTitleTypes.includes(text.titleType) ? text.titleType : 'h1';
  const title     = text.title ?? '';
  const body      = text.body ?? '';
  const bodyStyle = text.bodyStyle ?? '';

  const callToAction = (cta === true);

  return (
    <div className="image-and-text--text">
      { (titleType === 'h1') ?
        <h1>{title}</h1> : <h2>{title}</h2> }
      { (Array.isArray(body))
        ? body.map( (text, ix) => <p key={ix} className={bodyStyle}>{text}</p> )
        : <p className={bodyStyle}>{body}</p>
      }
      { callToAction ? <CTA className="image-and-text--cta"/> : ''}
    </div>
  )
}

const ImageAndText = ({ orientation, text, image, cta }) => {

  const allowedOrientations = ['textFirst', 'imageFirst'];
  const orientType = allowedOrientations.includes(orientation) ? orientation : 'textFirst';

  const layout = orientType === 'textFirst' ? 'five1six' : 'six1five';

  return (
    <div className="image-and-text" data-layout={layout}>
      { (orientType === 'textFirst')
        ? <><Text text={text}/> <br/> <Image image={image}/></>
        : <><Image image={image}/> <br/> <Text text={text} cta={cta}/></>}
    </div>
  )
};

export default ImageAndText;
// components
import Header     from '../components/Header';
import PageTitle  from '../components/PageTitle';
import Slideshow  from '../components/Slideshow';
import ImageBlock from  '../components/ImageBlock';
import { Parallax } from 'react-scroll-parallax';

// utils
import { useTitle } from 'react-use';

// content
import { homepage } from '../assets/images';

const images = [ homepage.home1, homepage.home2, homepage.home3, homepage.home4, homepage.home5, homepage.home6, {...homepage.homeBg, parallaxY : 20} ];

const QuoteMaker = ({text, author}) => {

  return (
    <div className="quote">
      <p className="quote--text">{text}</p>
      <p className="quote--author">– {author}</p>
    </div>
  )
}

const min = 80;
const max = 130;

const ImageMaker = ({img}) => {
  const rand = min + Math.random() * (max - min);
  const parralaxY = img.parallaxY ? [-img.parallaxY, img.parallaxY] : ['-'+rand+'px', '20px'];
  return (
    <Parallax className="idk" y={parralaxY} tagOuter="figure">
      <img src={img.src} srcSet={img.srcset} alt={img.alt}/>
    </Parallax>
  )
}

const HomePage = () => {

  useTitle('PixPix | Home');

  return (
    <div className="App" data-page="homepage">
      <Header alternative={true}/>
      <div className="home">
        <div className="image--wrapper">
          { images.map( (img, ix) => <ImageMaker key={ix} img={img} />) }
        </div>
        <div className="title--wrapper">
          <PageTitle content="Enjoy a lifetime of memories through beautiful photos delivered to your door" ctaDisplay={true}/>
          <br/>
          <QuoteMaker author="Andy Warhol" text={`“The best thing about a picture is that it never changes, even when the people in it do”`}/>
        </div>
      </div>
      <ImageBlock />
      <Slideshow showHIT={true}/>
    </div>
  )
}

export default HomePage;
import CTA from "./CTA.js";
import { NavLink } from "react-router-dom";

// import to make slideshow animate
import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useInterval } from "react-use";

// import the slideshow images
import { icons, slideshow } from "../assets/images";

const Slideshow = ({ showHIT = false }) => {

  // links setup
  const appStoreUrl = process.env.REACT_APP_APPSTORE_URL    ?? '';
  const googlePlayUrl = process.env.REACT_APP_PLAYSTORE_URL ?? '';
  const getExternalLink = (href, text) => {
    return (
      <a href={href} target="_blank" rel="noopener noreferrer">{ text }</a>
    )
  }

  // set up for animation
  const [ix, setIx] = useState(0);
  const [delay, ] = useState(3000);
  const incrementIx = () => {
    if( ix >= imgArray.length - 1 ) { setIx(0); } else { setIx(ix + 1); }
  }
  const [imgArray, ] = useState([ slideshow.content1, slideshow.content2, slideshow.content3, slideshow.content4, slideshow.content5 ]);


  // Timer that changes images after delay milliseconds
  useInterval( () => { incrementIx(); }, delay );

  // const text = "Download the Pixpix app from the Apple App Store and Google Play Store and you’re ready to order high-quality digital photo prints online from your Instagram or Photos Library account; delivered within 72 hours."

  const homepageLayout = showHIT ? 'HIT' : '';

  return (
    <div className={"slideshow " + homepageLayout}>
      <div className="slideshow--image">
        <AnimatePresence>
          <motion.img
            className="content"
            key={imgArray[ix].src}
            src={imgArray[ix].src}
            srcSet={imgArray[ix].srcset}
            alt={imgArray[ix].alt}
            initial={{ scale: 0.81, opacity: 0, y: '-5.2%' }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}

            transition={{ duration: 0.5 }}
          />
        </AnimatePresence>
        <img className="foreground"
          srcSet={slideshow.foreground.srcset}
          src={slideshow.foreground.src} alt={slideshow.foreground.alt}/>
      </div>
      { showHIT
        ? <NavLink className="slideshow--HIT" to="/how-it-works" exact>See how it works <img src={icons.arrowUpRight.src} alt={icons.arrowUpRight.alt} width="28" height="28"/></NavLink>
        : ''
      }
      <div className="slideshow--text">
        <p>
          Download the Pixpix app from the
          { appStoreUrl ? getExternalLink(appStoreUrl, ' Apple App Store ') : " Apple App Store " }
          and
          { googlePlayUrl ? getExternalLink(googlePlayUrl, ' Google Play Store ') : " Google Play Store " }
          and you’re ready to order high-quality digital photo prints online from your Instagram or Photos Library account; delivered within 72 hours.
        </p>
        <CTA className="slideshow--text--buttons" />
      </div>
    </div>
  )

}

export default Slideshow;
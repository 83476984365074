
import privacy from '../assets/privacy.js';
import TextPage from './TextPage.js';

const PrivacyPolicyPage = () => {

  return(
    <TextPage title="Privacy Policy" content={privacy} pageTitle="Pixpix | Privacy Policy" dataPage="terms-of-use" contactBlurb={true}/>
  );
};

export default PrivacyPolicyPage;
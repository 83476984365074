
import { useState } from "react";

//
// FAQ component
//

const FAQs = ({ content }) => {

  const [active, setActive] = useState(null);

  const toggleActive = (ix) => {
    if (active === ix) {
      setActive(null);
    } else {
      setActive(ix);
    }
  }

  const openAnswer = (event, id) => {
    const eltWrapper = document.getElementById(id + '--wrapper');
    const index = eltWrapper ? eltWrapper.getAttribute('data-index') : '';
    if (index)
      toggleActive(index);
    event.stopPropagation();
  }

  const replaceLists = (string, lists, iteration = 0) => {

    // early out when we have finished iterating
    if (!lists[iteration])
      return string;

    const strArray = string.split("#list");

    if (strArray.length > 1){
      return (
        <>
          {strArray.shift()}
          <p className="faqs--category--answer list">{lists[iteration]}</p>
          {replaceLists(strArray.join('#list'), lists, iteration + 1)}
        </>
      )
    } else {
      return replaceLists(string, lists, iteration + 1);
    }

  }

  const replaceLinks = (string, links, iteration = 0) => {

    // early return
    if (!links[iteration])
      return string;

    const linkLocal = links[iteration].startsWith('#');
    const linkMailto = links[iteration].startsWith('mailto:');
    const strArray = string.split('#link');

    if (strArray.length > 1){
      return (
        <>
          {strArray.shift()}
          { linkLocal
          ? <a href={links[iteration].replaceAll(' ', '-')} onClick={(e) => openAnswer(e, links[iteration].replaceAll(' ', '-').replace('#', ''))}>{links[iteration].replaceAll('#', '')}</a>
            : linkMailto
              ? <a href={links[iteration]} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>{links[iteration].replace('mailto:', '')}</a>
              : <a href={links[iteration].replaceAll(' ', '-')} target="pixpix-external" onClick={(e) => e.stopPropagation()}>here</a>
            }
          {replaceLinks(strArray.join("#link"), links, iteration + 1)}
        </>
      )
    } else {
      return replaceLinks(string, links, iteration + 1);
    }
  }

  const qas = (qas, category) => {
    return (
      qas.map( (qa, ix) => {
        const index = category + '-' + ix;
        const activeClass = (active === index ? 'active' : '')
        const answer = qa.links ? replaceLinks(qa.answer, qa.links) : qa.lists ? replaceLists(qa.answer, qa.lists) : qa.answer;
        return (
          <div className={"faqs--category--wrapper " + activeClass} onClick={() => toggleActive(index)} key={index} id={qa.question.replaceAll(' ', '-') + '--wrapper'} data-index={index}>
            <p id={qa.question.replaceAll(' ', '-')} className="faqs--category--question">
              {qa.question}
            </p>
            <span className="faqs--category--answer">
               {answer}
            </span>
          </div>
        )
      })
    )
  }

  return (
    <>
      <div className="faqs">
        <h1 className='faqs--title'>FAQs</h1>
        <div className="faqs--container">
          { content.map( (element, ix) => {
            return (
              <div className="faqs--category" key={ix}>
                <p className="faqs--category--title">{element.category}</p>
                {qas(element.qas, ix)}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default FAQs;
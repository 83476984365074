
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useWindowSize } from "react-use";
import React from "react";

import { icons } from "../assets/images";

//
// Image block component for home page
//

const ImageBlock = () => {

  // Special hook that checks if div is on screen (by more than 40%)
  const useOnScreen = (ref) => {

    const [animated, setAnimated] = useState(false);
    const [isIntersecting, setIntersecting] = useState(false)

    React.useEffect(() => {
      const observer = new IntersectionObserver( ([entry]) => {
          setIntersecting(entry.isIntersecting);
          if(!animated && entry.isIntersecting) {
            setAnimated(true);
            animate();
          }
        },
        {threshold: 0.5}
      );

      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [ref, animated])

    return isIntersecting
  }

  const ref = React.useRef();
  useOnScreen(ref);

  const {width, height} = useWindowSize();
  var calcScale = 0.37;
  var calcTranslateX = 30;
  if (width > 1280) {
    const widthRatio = 1280/width;
    calcScale = (1 - (1 - widthRatio))/2;
    calcTranslateX = calcTranslateX * widthRatio;
  }
  const scale = calcScale;
  const translateX = calcTranslateX + '%';

  var calcTranslateY = 10;
  if (height > 1440) {
    const heightRatio = 1440 / height;
    calcTranslateY = calcTranslateX * (1 + heightRatio);
  }
  const translateY = '-' + calcTranslateY + '%';

  const bgControls = useAnimation();
  const imgControl1 = useAnimation();
  const imgControl2 = useAnimation();
  const textControls = useAnimation();

  const standardDuration = {duration: 1};
  const standardDurationAndDelay = {...standardDuration, delay: 1};
  const standardDurationAndDelay2 = {...standardDuration, delay: 2};

  const variants = {
    hidden: { opacity: 0, transition: standardDuration },
    visible: { opacity: 1, transition: standardDuration },
    floatUp: { opacity: 1, y: "-15rem", transition: standardDuration  },
    bgTransition: { scale: scale, x: translateX, y: translateY, rotate: -5, backgroundSize: 'contain', transition: standardDuration },
    scaleImageIntoView1: { opacity: 1, scale: scale, x: translateX, y: translateY, rotate: 8, transition: standardDurationAndDelay },
    scaleImageIntoView2: { opacity: 1, scale: scale, x: translateX, y: translateY, rotate: 0, transition: standardDurationAndDelay2 },
  }

  const animate = () => {
    bgControls.start('bgTransition');
    imgControl1.start('scaleImageIntoView1');
    imgControl2.start('scaleImageIntoView2');
    textControls.start('floatUp');
  }

  const title = 'When time stands still.';
  const text  = 'At PIXPIX we want you to experience the simple pleasure of remembering that moment. Enjoy the kaleidoscope of enduring photographic images that represent a life well lived.';

  return (
    <div className="image-block" ref={ref}>
      <div className="image-block--desktop">
        <AnimatePresence>
          <motion.div
            className="image-block--main-image"
            animate={bgControls}
            variants={variants}
            />
        </AnimatePresence>
        <AnimatePresence>
            <motion.div
              className="image-block--image-2"
              animate={imgControl1}
              variants={variants}
            />
        </AnimatePresence>
        <AnimatePresence>
            <motion.div
              className="image-block--image-3"
              animate={imgControl2}
              variants={variants}
            />
        </AnimatePresence>

        <div className="image-block--body">
          <AnimatePresence>
            <motion.h1
              className="image-block--title"
              key={1}
              animate={textControls}
              variants={variants}
            >{title}</motion.h1>
            <motion.p
              className="image-block--text"
              key={2}
              animate={textControls}
              variants={variants}
            >{text}</motion.p>
          </AnimatePresence>
        </div>
      </div>

      <div className="image-block--mobile">
        <div className="image-block--mobile--body">
          <h1 className="image-block--mobile--title">{title}</h1>
          <p className="image-block--mobile--text">{text}</p>
          <NavLink className="image-block--mobile--link" to="/how-it-works" exact>See how it works <img src={icons.arrowUpRight.src} alt={icons.arrowUpRight.alt} /></NavLink>
        </div>
      </div>
    </div>
  )
}

export default ImageBlock;
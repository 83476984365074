
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUs = ({ title, subtitle }) => {

  const [name, setName] =           useState('');
  const [email, setEmail] =         useState('');
  const [message, setMessage] =     useState('');
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] =   useState('');

  const supportEmail = 'hello@pixpix.app';


  const makeEmailLink = (title) => {
    const titleShort = title.replace(supportEmail, '');

    return (
      <>
        {titleShort}
        <a href={ 'mailto:' + supportEmail } target="_blank" rel="noreferrer" className="email-link">
          {supportEmail}
        </a>
      </>
    )
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formdata = new FormData(event.target);

    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
      console.log(this.readyState, this.status, this.response);

      if (this.readyState === 4) {
        setSubmitted(true);

        if (this.status === 200) {
          setResponse('Thanks for your enquiry, we will be in touch soon.')
        } else {
          setResponse(this.errorMessage ?? 'We ran into an issue. Please try again later.')
        }
      }
    }

    xmlhttp.open("POST", "api/ajax.php");
    xmlhttp.send(formdata);
  }

  const form = () => {
    return(
      <form className="contact-form--body" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name" className="label">Your Name</label>
          <input type='text' className="input"
            name="name" id="name" required
            value={name} onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="email" className="label">Your Email</label>
          <input type='email' className="input"
            name="email" id="email" required
            value={email} onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="message" className="label">Your Message</label>
          <textarea className="input"
            name="message" id="message" required
            value={message} onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY}
          />
        </div>
        <div>
          <input type='submit' className="pill" value="Submit"/>
        </div>
      </form>
    );
  }

  const postSubmitMessage = () => {
    return(
      <div className="contact-form--body">
        <h1>{response}</h1>
        <a href="/">
          <button className="pill">Back</button>
        </a>
      </div>
    );
  }

  return(
    <div className="contact-form">
      <div className="contact-form--text">
        <h1 className="contact-form--title">{ title.includes(supportEmail) ? makeEmailLink(title) : title }</h1>
        <h2 className="contact-form--subtitle">{subtitle}</h2>
      </div>
      { submitted ? postSubmitMessage() : form() }
    </div>
  )
}

export default ContactUs;

import terms from '../assets/terms';
import TextPage from './TextPage';

const TermsOfUsePage = () => {

  return(
    <TextPage title="Terms of use" content={terms} pageTitle="Pixpix | Terms of use" dataPage="terms-of-use" contactBlurb={true}/>
  );
};

export default TermsOfUsePage;
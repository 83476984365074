
import CTA from "./CTA";

//
// Page body
//

const PageBody = ({ title, content, className = 'body', cta = false, contactBlurb = false}) => {

  const callToAction = cta ?? false;

  const appStoreUrl = process.env.REACT_APP_APPSTORE_URL    ?? '';
  const googlePlayUrl = process.env.REACT_APP_PLAYSTORE_URL ?? '';

  return(
    <div className={className}>
      <h1 className={className + '--header'}>{title}</h1>
      <p className={className + '--body'}>
        { (content === 'HIT') && (appStoreUrl && googlePlayUrl) ?
          <>Pixpix is available on the <a href={ googlePlayUrl } target="_blank" rel="noopener noreferrer">Android</a> and <a href={ appStoreUrl } target="_blank" rel="noopener noreferrer">iOS</a> app stores. Download the app, connect your Instagram and Photos Library and you’re all set to order high quality prints - delivered direct to your door.</> :
          content
        }
      </p>
      { callToAction ?  <CTA /> : ''}
      { contactBlurb
        ? <p className={className + "--body"}>
          Contact Us
          <br/><br/>Don't hesitate to contact us if you have any questions.
          <br/><br/>•  Via this Link: <a href="/contact">www.pixpix.app/contact</a>
        </p>
        : ''}
    </div>
  )

}

export default PageBody;

import { logos }       from '../assets/images.js';
import { Link, NavLink }   from 'react-router-dom';
import { useState } from 'react';

//
// Header component with navigation
//

const Header = () => {

  const [openMenu, setOpenMenu] = useState(false);

  const toggleOpenMenu = () => {
    setOpenMenu(!openMenu);
    correctScroll()
  }

  const correctScroll = () => {
    openMenu ? document.body.style.overflowY = 'hidden' : document.body.style.overflowY = 'auto';
  }

  const desktopNav = [
    {to: '/how-it-works',  text: 'How it Works'},
    {to: '/about-us',      text: 'About Us'},
    {to: '/pricing',       text: 'Pricing'},
    {to: '/faq',           text: 'FAQ'},
    {to: '/contact-us',    text: 'Contact Us'},
  ];
  const mobileNav = [
    {to: '/terms-of-use',   text: 'Terms of Use'},
    {to: '/privacy-policy', text: 'Privacy Policy'},
  ];

  const getDesktopNav = () => {
    return (
      <div className="header--nav">
        {desktopNav.map( (link, ix) => {
          return <NavLink key={ix} to={link.to} exact>{link.text}</NavLink>
        })}
      </div>
    )
  }

  const getMobileNav = () => {
    return (
      <div className="header--mobile-nav">
        <NavLink to="/" exact>Home</NavLink>
        {[...desktopNav, ...mobileNav].map( (link, ix) => {
          return <NavLink key={ix} to={link.to} exact>{link.text}</NavLink>
        })}
      </div>
    )
  }

  correctScroll();

  return(
    <header className="header">
      <Link to="/">
        <img src={logos.mainLogo.src} alt={logos.mainLogo.alt} className='header--logo'
        width="112"/>
      </Link>

      {getDesktopNav()}

      <div className="header--mobile-menu">
        <button onClick={ () => toggleOpenMenu() }
          className="header--toggle-btn">
          <div>
            { openMenu ? "CLOSE" : "MENU" }
          </div>
          <div className={ "header--hamburger-wrapper" + (openMenu ? " close" : '')}>
            <span className="header--hamburger"></span>
            <span className="header--hamburger"></span>
            <span className="header--hamburger"></span>
          </div>
        </button>

        { openMenu ?
          <>
            {getMobileNav()}
          </> : ''
      }
      </div>
    </header>
  );
}

export default Header;

import { icons } from '../assets/images.js';

//
// Call to action with play/app store links
//

const CTA = ({ className }) => {

  const appStoreUrl = process.env.REACT_APP_APPSTORE_URL    ?? '';
  const googlePlayUrl = process.env.REACT_APP_PLAYSTORE_URL ?? '';

  const classList = "cta " + className;

  const GetComingSoon = () => {
    return (
      <h2>Coming soon.</h2>
    )
  }

  // const GetBetaTesting = () => {
  //   return (
  //     <h3>Beta testing open now – <a href="/beta">sign up</a></h3>
  //   )
  // }

  const GetButtons = () => {
    return (
      <>
        <a href={appStoreUrl} target="_blank" rel="noopener noreferrer"><img src={icons.appStore.src} alt={icons.appStore.alt}/></a>
        <a href={googlePlayUrl} target="_blank" rel="noopener noreferrer"><img src={icons.playStore.src} alt={icons.playStore.alt}/></a>
      </>
    )
  }

  return (
    <div className={classList}>
      {
        ( appStoreUrl === "" || googlePlayUrl === "" ) ?
          <GetComingSoon /> : <GetButtons />
      }
    </div>
  )
}

export default CTA;
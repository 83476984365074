import Header from "../components/Header";
import ImageAndText from "../components/ImageAndText";
import Slideshow from "../components/Slideshow";
import { useTitle } from 'react-use';

import { aboutUs } from "../assets/images";

//
// About us page
//

const AboutUsPage = () => {

  useTitle('Pixpix | About us')

  const images = [
    { meta: aboutUs.frames, divStyle: 'extra-width' },
    { meta: aboutUs.couple, divStyle: 'extra-width' },
    { meta: aboutUs.iphone, divStyle: 'background' },
  ];
  const texts = [
    { title: 'About us', titleType: 'h1', body: `We are three friends who got together late one summer afternoon.
    We were simply talking, sharing some of our thinking and some of our frustrations. Having daughters all going off to further education and wanting to fill their lives with amazing memories, filled with friends and family, love and laughter – photo walls.` },
    { title: 'We realised that it wasn’t easy to print all of these digital photos.', titleType: 'h2', body: `And then we realised that even our homes, that are similarly filled with love, family and friends were dated, we were living in our past. And why?
    Because the process to print our most cherished and favourite new photos was simply far too difficult.`},
    { title: 'And so, Pixpix was conceived.', titleType: 'h2', body: [`Making your photos come to life, with the ease of an app on your phone that was simple to use, with a single focus on photos, bringing your homes, your walls, your offices to life.`, `Photos made easy.`] },
  ];

  return (
    <div className="App" data-layout="about-us">
      <Header alternative={false}/>
      <ImageAndText orientation="textFirst" text={texts[0]} image={images[0]}/>
      <ImageAndText orientation="imageFirst" text={texts[1]} image={images[1]}/>
      <ImageAndText orientation="textFirst" text={texts[2]} image={images[2]}/>
      <Slideshow />
    </div>
  )
}

export default AboutUsPage;
import CTA from "./CTA.js";

const PageTitle = ({ content, className = 'title', ctaDisplay = false }) => {

  const cta = !!ctaDisplay;

  return(
    <div className={className} data-layout={ cta ? 'title-cta' : '' }>
      <h1 className={className + '--header'}>{content}</h1>
      { cta ? <CTA className={className + '--buttons'} /> : '' }
    </div>
  )

}

export default PageTitle;
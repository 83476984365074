
import Header from "../components/Header";
import FAQs from "../components/FAQs";
import faq from "../assets/faq";

import { useTitle } from 'react-use';


//
// FAQ page
//

const FAQPage = () => {

  useTitle('Pixpix | FAQs')

  return (
    <div className="App" data-layout="faq">
      <Header alternative={false}/>
      <FAQs content={faq} />
    </div>
  )
}

export default FAQPage;
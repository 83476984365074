
import Header from "../components/Header";
import PageBody from "../components/PageBody";
import Slideshow from "../components/Slideshow";

import { useTitle } from 'react-use';

import { hit } from "../assets/images";

//
// How it Works page
//

const HowItWorksPage = () => {

  useTitle('Pixpix | How it works')

  return (
    <div className="App" data-page="how-it-works">
      <Header alternative={false}/>
      <div className="page-body-wrapper">
        <PageBody title="How it works" content="HIT"
          className="hit-intro" cta={true}/>
      </div>

      <div data-layout="halfAndHalf" className="hit--tablet">
        <img src={hit.hit2.src} alt={hit.hit2.alt} />
        <img src={hit.hit1.src} alt={hit.hit1.alt}/>
      </div>
      <div className="hit--mobile">
        <img src={hit.hitMobile.src} alt={hit.hitMobile.alt}/>
      </div>
      <Slideshow />
    </div>
  )

}

export default HowItWorksPage;